const highestMaxMultiplier = 3
const highestMaxSpendFactor = 3.5
const knownFactors = [
  { key: 'user_hour', label: 'Hour', unavailable: true },
  { key: 'user_wday_id', label: 'Weekday', unavailable: true },
  { key: 'user_tday_id', label: 'user_tday_id', unavailable: true },
  { key: 'ad_id', label: 'Ad' },
  { key: 'ad_size_id', label: 'Ad size' },
  { key: 'ad_position_id', label: 'Ad position' },
  { key: 'nth_view', label: 'Nth view' },
  { key: 'keyword_recency_id', label: 'Keyword recency', unavailable: true },
  { key: 'domain_id', label: 'Domain' },
  { key: 'hour_id', label: 'Hour' },
  { key: 'city_id', label: 'City' },
  { key: 'subdiv2_id', label: 'Subdivision lvl 2' },
  { key: 'subdiv1_id', label: 'Subdivision lvl 1' },
  { key: 'country_id', label: 'Country' },
  { key: 'postal_code', label: 'Postal code' },
  { key: 'data_center', label: 'Data center' },
  { key: 'keyword_id', label: 'Keyword' },
  { key: 'data_provider_id', label: 'Data provider' },
  {
    key: 'keyword_with_data_provider_id',
    label: 'Keyword with data provider',
    unavailable: true
  },
  { key: 'segment_id', label: 'Segment' },
  { key: 'context_l1_id', label: 'Context lvl 1' },
  { key: 'context_l2_id', label: 'Context lvl 2' },
  { key: 'exchange_id', label: 'Exchange' },
  { key: 'browser_id', label: 'Browser' },
  { key: 'device_type_id', label: 'Device type' },
  { key: 'geo_fence_id', label: 'Geo fence' },
  { key: 'geo_fence_recency_id', label: 'Geo fence recency' },
  { key: 'is_mobile', label: 'Is mobile' },
  { key: 'is_mobile_app', label: 'Is mobile app' },
  { key: 'mobile_app_id', label: 'Mobile app' },
  { key: 'deal_id', label: 'Deal' },
  { key: 'device_make_id', label: 'Device make' },
  { key: 'device_os_id', label: 'Device OS' },
  { key: 'device_osv_id', label: 'Device OS version', unavailable: true },
  { key: 'day_type_id', label: 'Day type' },
  { key: 'week_day_id', label: 'Week day' },
  { key: 'recency_id', label: 'Recency' },
  { key: 'domain_id,exchange_id,placement_id', label: 'Sifi placement' },
  { key: 'test_id', label: 'Test', unavailable: true },
]
const lowestMaxMultiplier = 1
const lowestMaxSpendFactor = 1

const constants = {
  defaultMaxMultiplier: 2,
  factorModelAttributes: {
    cpm: {
      controlField: 'auto_cpm_control',
      defaultValue: 0.9,
      label: 'CPM factor',
      maxValue: highestMaxMultiplier,
      minValue: 0.01,
      valueField: 'cpm_factor',
    },
    cpmTarget: {
      defaultValue: 0.9,
      label: 'CPM target',
      maxValue: highestMaxMultiplier,
      minValue: 0.01,
      valueField: 'cpm_target',
    },
    initialize: {
      label: 'Initialize factor multipliers',
    },
    maxMultiplier: {
      defaultValue: highestMaxMultiplier,
      label: 'Max multiplier',
      maxValue: highestMaxMultiplier,
      minValue: lowestMaxMultiplier,
      valueField: 'max_multiplier',
    },
    maxSpend: {
      defaultValue: highestMaxSpendFactor,
      description:
        'max_spend_factor is the highest value a spend_factor can ' +
        'reach when optimizing for performance. Valid range: ' +
        `${lowestMaxSpendFactor} - ${highestMaxSpendFactor}`,
      label: 'Max spend factor',
      maxValue: highestMaxSpendFactor,
      minValue: lowestMaxSpendFactor,
      valueField: 'max_spend_factor',
    },
    sampleRate: {
      controlField: 'auto_sample_rate_control',
      defaultValue: 0.05,
      label: 'Sample rate',
      maxValue: 1,
      minValue: 0,
      valueField: 'sample_rate',
    },
    sampleSizeTarget: {
      defaultValue: 0.1,
      label: 'Sample size target',
      maxValue: 1,
      minValue: 0,
      valueField: 'sample_size_target',
    },
    spend: {
      controlField: 'auto_spend_control',
      defaultValue: 1,
      description:
        'Spend factor is moved up and down to help the campaign ' +
        'spend. It goes to zero when the campaign is struggling to ' +
        'spend and moves up when spend is good to maximize performance.',
      label: 'Spend factor',
      maxValue: highestMaxMultiplier,
      minValue: 0.01,
      valueField: 'spend_factor',
    },
  },
  factorEditCategory: 'factor_multiplier',
  factorModelAttributeEditCategory: 'factor_model_attribute',
  highestMaxMultiplier,
  highestMaxSpendFactor,
  knownFactors: knownFactors.sort((a, b) => a.label > b.label ? 1 : -1),
  labelPerKey: knownFactors.reduce((r, { key, label }) => Object.assign(r, { [key]: label }), {}),
  lowestMaxMultiplier,
  lowestMaxSpendFactor,
}

module.exports = constants
