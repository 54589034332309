const objectHasProperty = require('./objectHasProperty')
const cleanCSVValue = require('./strings/cleanCSVValue')

const arrayUtils = {
  getNumbersFromText(str = '') {
    return arrayUtils.integerize(
      str
        .replace(/[^0-9]+/gi, ' ')
        .trim()
        .split(' ')
    )
  },
  integerize: array => {
    return array instanceof Array && array.length > 0
      ? array
        .map(member => parseInt(member))
        .filter(n => typeof n === 'number' && !isNaN(n))
      : []
  },

  /**
   * Generate csv string from array
   *
   * @param {Array} array - row content
   * @param {Array} headers - keys to get from each row
   * @param {Boolean} includeHeaders - whether to include header row
   * @param {Boolean} noQuotes - whether to wrap each value in quotes
   *
   * @returns {String}
   */
  toCommaSeparatedValues(
    array,
    headers,
    includeHeaders = false,
    noQuotes = false
  ) {
    let content = includeHeaders ? `${headers.toString()}\n` : ''

    array.forEach(row => {
      headers.forEach((field, fieldIdx) => {
        const valueIsAssigned = objectHasProperty(row, field)
        let value = row[field]
        const valueType = typeof value

        value = valueType === 'number' && value === 0 ? '0' : value
        content +=
          (noQuotes ? '' : '"') +
          (valueIsAssigned ? cleanCSVValue(value) : 'NA') +
          (fieldIdx < headers.length - 1
            ? noQuotes
              ? ','
              : '",'
            : noQuotes
              ? ''
              : '"')
      })
      content += '\n'
    })

    return content
  },

  // Duplicate removal from array
  removeDuplicates(arr, coerceValuesToInteger) {
    const out = arr.reduce((unique, i) => {
      if (!unique.includes(i)) {
        unique.push(i)
      }

      return unique
    }, [])

    return coerceValuesToInteger ? arrayUtils.integerize(out) : out
  },

  // Find minimum and maximum of array of values
  findExtremes(values) {
    return {
      minimum: values.length > 0 ? Math.min(...values) : undefined,
      maximum: values.length > 0 ? Math.max(...values) : undefined,
    }
  },

  // Remove specific item from array
  removeItem: (array, item) => {
    do {
      const idx = array.indexOf(item)

      if (idx > -1) {
        array.splice(idx, 1)
      }
    } while (array.includes(item))

    return array
  },

  // Extract values of keys in array of hashes
  extract(array, key) {
    return array.reduce((extracted, member) => {
      if (member[key] !== undefined) extracted.push(member[key])
      return extracted
    }, [])
  },

  /*
   * Add function to array prototype so that I can search
   * within arrays for hashes by value of their keys
   */
  lookup(array, key, value) {
    return array.find(item => item[key] === value)
  },

  splitToBatches(array, limit) {
    const batches = []
    const arrayCopy = [...array]

    do {
      const batch = arrayCopy.splice(0, limit)

      if (batch.length > 0) batches.push(batch)
    } while (arrayCopy.length)

    return batches
  },

  lookupIndex(array, key, value) {
    const idx = array.findIndex(member => member[key] === value)

    return idx > -1 ? idx : undefined
  },

  // Returns array of members that are not within the smaller array
  difference(smaller, larger) {
    return larger.filter(i => !smaller.includes(i))
  },
  intersection(arrayA, arrayB) {
    return arrayB.filter(m => arrayA.includes(m))
  },
  combine(arrayA, arrayB) {
    let result = []

    arrayA.forEach(valueA => {
      const resultA = []

      arrayB.forEach(valueB => {
        resultA.push(`${valueA} ${valueB}`)
      })
      result = result.concat(resultA)
    })
    return result
  },

  // Convert array to hash of key/values
  implode(array, key) {
    const hash = {}
    array.forEach(member => {
      if (member[key] !== undefined) {
        // convert value to string
        hash[`${member[key]}`] = member
      }
    })
    return hash
  },

  deduplicateByKey(array, key) {
    const hash = arrayUtils.implode(array, key)
    const deduplicated_array = arrayUtils.explode(hash)

    return deduplicated_array
  },

  // For hash explosion, conversion into array
  explode(hash) {
    const result = []

    for (const key in hash) result.push(hash[key])

    return result
  },
  median(values) {
    if (values instanceof Array && values.length > 0) {
      const sortedVals = [...values].sort((a, b) => a - b)
      const half = Math.floor(sortedVals.length / 2)

      if (sortedVals.length % 2) {
        return sortedVals[half]
      } else {
        return (sortedVals[half - 1] + sortedVals[half]) / 2.0
      }
    } else {
      return undefined
    }
  },
  sum(values) {
    return values.reduce((a, b) => a + b, 0)
  },
  average(values) {
    return values.length > 0
      ? arrayUtils.sum(values) / values.length
      : 0
  },
}

module.exports = arrayUtils
