const {
  recencies,
  types,
  statuses,
  subTypeValues,
  domainListTypes,
  keywordLowBid,
  modelTypes,
} = require('../campaigns/constants')
const { coordsFromString } = require('../utilities/geo')
const arrayUtils = require('../utilities/array')
const objectHasProperty = require('../utilities/objectHasProperty')
const { labelPerKey } = require('../factorModels/constants')
const truthyValues = [
  'true',
  true,
  1,
  '1',
  'Yes',
  'yes',
  't',
  'on',
  'y',
]
const enableDisableValues = ['on', 'off']
const binaryValues = [0, 1]
const validate = {
  enableDisableValues,
  truthyValues,
  binaryValues,
  array: (arr, validator) =>
    arr instanceof Array &&
    arr.length > 0 &&
    (typeof validator !== 'function' || arr.every(validator)),
  campaign_id: (id) => validate.number(id, 1, 9999999999),
  campaignIds: (ids) => {
    if (ids instanceof Array && ids.length) {
      return ids.filter(validate.campaign_id).length === ids.length
    } else return false
  },
  campaignStatusId: (id) => {
    const campaignStatusIds = arrayUtils.integerize(
      Object.keys(statuses),
    )
    return campaignStatusIds.includes(id)
  },
  campaignTypeId: (id) => {
    const campaignTypeIds = arrayUtils.integerize(Object.keys(types))
    return campaignTypeIds.includes(id)
  },
  campaignSubTypeValue: (value) => {
    return subTypeValues.includes(value)
  },
  campaignName: (name) => validate.string(name, { min: 3, max: 200 }),
  company_id: (id) => validate.number(id, 0, 999999999),
  daypartingString(str) {
    return (
      typeof str === 'string' &&
      str.length === 24 * 7 &&
      str.includes('1') &&
      str.split('').every((char) => ['0', '1'].includes(char))
    )
  },
  dealIdentifier: (str) => validate.string(str, { min: 3, max: 128 }),
  domainName: (str) => validate.string(str, { min: 1, max: 128 }),
  domainListType: (value) => {
    if (validate.isInt(value)) {
      const domainListTypeIDs = arrayUtils.integerize(
        Object.keys(domainListTypes),
      )
      return domainListTypeIDs.includes(value)
    } else {
      return Object.values(domainListTypes).includes(value)
    }
  },
  factorsPayload: (factors) =>
    factors instanceof Array &&
    factors.every(
      (f) =>
        f.group === 'user' &&
        f.value >= 0 &&
        f.value <= 3 &&
        objectHasProperty(labelPerKey, f.key) &&
        objectHasProperty(f, 'id'),
    ),
  fcap: ({ howManyTimes, hours }) =>
    validate.isInt(howManyTimes) &&
    validate.isInt(hours) &&
    validate.number(howManyTimes, 0, 100) &&
    validate.number(hours, 0, 24),
  geoFenceCoordinates: (coords) =>
    validate.array(
      coordsFromString(coords),
      ([lon, lat]) =>
        validate.number(lon, -180, 180) &&
        validate.number(lat, -90, 90),
    ),
  hour: (num) => validate.number(num, 0, 23),
  id: (val) => validate.number(val, 1, Math.pow(10, 16)),
  idArray: (array) => validate.ids(array),
  ids: (ids) => validate.array(ids, validate.id),
  IOSID: (input) => {
    const num = Number(input)
    return validate.number(num, 10000000, 999999999999)
  },
  isBinary: (val) => binaryValues.includes(val),
  isEnabledDisabled: (val) =>
    enableDisableValues.includes(val.toLowerCase()),
  isHexColor: (val) => /^#[0-9A-F]{6}$/i.test(val),
  isInt: (number) => {
    return typeof number === 'number' && number % 1 === 0
  },
  isObject(value, allowBlank = false) {
    return (
      value !== null &&
      value instanceof Array !== true &&
      typeof value === 'object' &&
      (allowBlank || Object.keys(value).length > 0)
    )
  },
  isTruthy: (val) =>
    truthyValues.includes(
      typeof val === 'string' ? val.toLowerCase() : val,
    ),
  keyword: (str) => validate.string(str, { min: 2, max: 128 }),
  keywordBid: (bid) =>
    bid === null || validate.number(bid, keywordLowBid, 50),
  keywordSetName: (setName) =>
    validate.string(setName, {
      min: 8,
      max: 200,
    }),
  keywordSetPointerName: (setName) =>
    validate.string(setName, {
      min: 3,
      max: 200,
    }),
  // md5 for kawa '184588336379094'
  md5: (val) =>
    validate.isInt(val) && validate.number(val, 10, Math.pow(10, 16)),
  modelTypeId: (id) => modelTypes.map((t) => t.id).includes(id),
  multiDetailOptions: (options, DEFAULT_OPTIONS = {}) => {
    const validOptionKeys = ['only', ...Object.keys(DEFAULT_OPTIONS)]
    return Object.keys(options).every(
      (key) =>
        validOptionKeys.includes(key) &&
        typeof options[key] === 'boolean',
    )
  },
  number: (value, min = 1, max) => {
    const isNumber = typeof value === 'number'
    const notBelowMin = typeof min !== 'number' || value >= min
    const notOverMax = typeof max !== 'number' || value <= max
    return isNumber && notBelowMin && notOverMax
  },
  integer: (value, min = 1, max) => validate.isInt(value) && validate.number(value, min, max),
  numberDivisibleBy: (value, min = 1, max, divisibleBy) => {
    return (
      validate.number(value, min, max) &&
      (!divisibleBy || value % divisibleBy === 0)
    )
  },
  pacing: (value) => validate.number(value, 0.01, 100),
  packageURL: (input) => {
    const regex =
      /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
    return regex.test(input)
  },
  recencyId: (id) => recencies.map(({ id }) => id).includes(id),
  sqlDate: (input) => {
    const regex =
      /[0-9]{4}-[0-9]{2}-[0-9]{2}( [0-9]{2}:[0-9]{2}:[0-9]{2})?/
    return regex.test(input)
  },
  string: (str, { min = 1, max = 0 } = {}) =>
    typeof str === 'string' &&
    str.length >= min &&
    (max === 0 || str.length <= max),
  URL: (url) => {
    const regex =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
    return regex.test(url)
  },
}

module.exports = {
  campaignId: validate.campaign_id,
  companyId: validate.company_id,
  ...validate,
}
