const Validate = require('../validation')
const numeral = require('numeral')

const kawaLinkHtml = campaignId => {
  return `<a href="https://kawa.simpli.fi/auto/${campaignId}" ` +
    `target="_blank" ` +
    `class="snapshot-item-operator">` +
    `${campaignId}</a>`
}

const camelToSnake = string => string
  .replace(/[\w]([A-Z])/g, ([f, s]) => `${f}_${s}`)
  .toLowerCase()

const kebabToCamel = string => string
  .split('-')
  .map(
    (s, idx) => idx > 0
      ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
      : s
  ).join('')

const capitaliseFirstLetter = string => {
  return (string.charAt(0).toUpperCase() + string.slice(1))
}

const cleanComment = (string = '') => {
  return string.replace(/[^a-z0-9\-\s\n,.:$!()%?@#+]/ig, '')
}

/** Translates encoded chars and removes plus signs from a stored keyword
 * @param {String} keyword - The input string for keyword
 * @returns {String} - Cleaned keyword string
 */
const cleanDirty = keyword => {
  return decodeURI(keyword.toLowerCase()).replace(/\+/gi, ' ').trim()
}

// Not for keyword, rather input cleaning
const cleanIncoming = string => {
  return string.replace(/[^a-zA-Z0-9-:|.\s_@]/ig, ' ').trim()
}

const cleanNice = keyword => {
  return (
    keyword
      .toLowerCase()
      .replace(/[^a-z0-9-&.'_]/ig, ' ')
      .trim()
  )
}

const cleanNumber = string => parseInt(string.replace(/[^0-9]/ig, ''))

const cleanOut = keyword => {
  return (
    encodeURI(
      keyword.trim().replace(/\s\s+/g, ' ').replace(/\s/ig, '+')
    )
      .replace(/!/gi, '%21')
      .replace(/&/gi, '%26')
      .replace(/'/ig, '%27')
      .replace(/\//gi, '%2F')
  )
}

const cleanSearch = keyword => {
  return (
    keyword
      .toLowerCase()
      .replace(/\+/gi, ' ')
      .replace(/%21/gi, '!')
      .replace(/%26/gi, '&')
      .replace(/%27/gi, `'`)
      .replace(/%2F/gi, '/')
      .replace(/[^a-z0-9-&!.%'_,]/ig, ' ').trim()
  )
}

// Clean string from all but space, chars, numbers, dashes, then trim whitespace
const cleanString = (string = '') => string.replace(/[^a-z0-9-\s]/ig, '').trim()

function cleanToSearch(criteria) {
  return criteria
    .toLowerCase()
    .replace(/[^a-z0-9-%!.'&\\|]/ig, ' ')
    .trim()
    .replace(/\s/ig, '+')
    .replace(/!/ig, '%21')
    .replace(/'/gi, '%27')
    .replace(/&/gi, '%26')
}

const divideByLines = string => string
  .replace(/(?:\r\n|\r|\n|,)/gi, '|||')
  .split('|||')

const dollar = (number, includeCents = true) => {
  return isFinite(number) && !isNaN(number)
    ? includeCents
      ? numeral(number).format('$0,0.00')
      : numeral(number).format('$0,0')
    : `$${number}`
}

const extractDomainFromURL = url => {
  if (url === null) {
    return ''
  } else {
    const splitURL = url.split('/')
    const hostName = splitURL[2]

    if (hostName && hostName.length) {
      const hostNameArrayed = hostName.split('.')
      const domainLevelsLength = hostNameArrayed.length
      const idx = (
        domainLevelsLength < 3
          ? 0
          : 1
      )

      return hostNameArrayed[idx]
    } else {
      return url
    }
  }
}

const extractKeywordsFromLines = string => {
  return divideByLines(string).filter(Validate.keyword)
}
const extractDomainsFromLines = string => {
  return divideByLines(string).filter(Validate.domainName)
}

const fileSizeMByte = sizeBytes => {
  const sizeMByte = sizeBytes / 1024 / 1024
  const sizeTruncated = Number.parseFloat(sizeMByte).toPrecision(4)
  return `${sizeTruncated} MByte`
}

const formatKeywordsText = string => {
  return divideByLines(string).map(cleanNice).filter(
    string => Validate.keyword(string)
  )
}

const getDelimitedTextFromString = string => {
  const bits = string.replace(/[^0-9a-zA-Z+\-!\s.]/gi, '|||').split('|||')

  bits.forEach((text_part, idx) => {
    bits[idx] = text_part.trim()
  })

  return bits
}

// check if the provided string contains dots/spaces or is a number
const maybeDomainName = string => {
  return (
    string.indexOf('.') === -1 &&
    string.indexOf(' ') === -1 &&
    isNaN(Number(string))
  )
}

const replaceAt = (string, index, character) => {
  return (
    string.substring(0, index) + character +
    string.substring(index + character.length)
  )
}

const shortenIfLong = (string = '', limit = 30) => {
  return string.length > limit
    ? (string.substr(0, limit) + '...')
    : string
}

const snakeToCamel = string => {
  return string.replace(/(_\w)/g, ([, m]) => m.toUpperCase())
}

const spacedToSnake = string => string.replace(/\s/g, '_').toLowerCase()

const standardiseString = string => {
  const stringIsValid = Validate.string(string)
  return (
    capitaliseFirstLetter(
      (stringIsValid ? string : '').replace(/_/gi, ' ')
    )
  )
}

const stringIndexes = (string, query) => {
  const result = []
  let i
  while ((i = string.indexOf(query, i + 1)) >= 0) result.push(i)
  return result
}

const superscript = string => {
  return `<sup>${string}</sup>`
}

const transforms = {
  array: str => JSON.parse(str),
  boolean: str => {
    switch (str) {
      case 'true': case '1': return true
      case 'false': case 'undefined': case 'null': case '0': return false
      default: return Boolean(str)
    }
  },
  float: str => parseFloat(str),
  integer: str => parseInt(str),
  object: str => JSON.parse(str),
  simple_text_array: str => str.split(','),
  string: str => str,
}

const typeToString = {
  array: arr => JSON.stringify(arr),
  boolean: bool => `${!!bool}`,
  float: num => `${num}`,
  integer: num => `${num}`,
  object: obj => JSON.stringify(obj),
  simple_text_array: arr => arr.join(','),
  string: str => str,
}

const unavailableLabel = 'N/A'
const notInViewLabel = 'Not in view'

module.exports = {
  kawaLinkHtml,
  camelToSnake,
  cleanComment,
  cleanDirty,
  cleanIncoming,
  cleanNice,
  cleanNumber,
  cleanOut,
  cleanSearch,
  cleanString,
  cleanToSearch,
  capitaliseFirstLetter,
  divideByLines,
  dollar,
  extractDomainFromURL,
  extractDomainsFromLines,
  extractKeywordsFromLines,
  fileSizeMByte,
  formatKeywordsText,
  getDelimitedTextFromString,
  kebabToCamel,
  maybeDomainName,
  notInViewLabel,
  replaceAt,
  shortenIfLong,
  snakeToCamel,
  spacedToSnake,
  standardiseString,
  stringIndexes,
  superscript,
  transforms,
  typeToString,
  unavailableLabel
}
