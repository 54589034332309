import { kawaConstants } from '@/constants'

export function matchesAccess({ access, path, method }) {
  const methodMatching =
    access.method === '*' ||
    access.method.toLowerCase() === method.toLowerCase()
  const pathMatching = access.path.endsWith('*')
    ? path.startsWith(access.path.slice(0, -1))
    : access.path === path

  return methodMatching && pathMatching
}

export const matchAccessWith = ({ list, listType, method, path }) => {
  // For routes matching legacy_client_url/paths
  path = path.replace(kawaConstants.client.legacy.path, '')
  // For routes matching new_client_url/new/#/path/
  path = path.replace(kawaConstants.client.new.path, '')
  // For routes matching /new/#/path/
  path = path.replace(kawaConstants.client.new.subPath, '')
  // For remnants of the vuejs routing
  path = path.replace('/#/', '/')
  // Remove trailing
  if (path.length > 1 && path.endsWith('/')) {
    path = path.slice(0, -1)
  }
  if (listType === kawaConstants.access.denyListType) {
    return !list.some((access) => matchesAccess({ access, method, path }))
  }
  return list.some((access) => matchesAccess({ access, method, path }))
}
