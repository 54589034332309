/**
 * @typedef {Object} Field
 * @property {string} key - The key of the field
 * @property {string} label - The label of the field
 * @property {function} [formatter] - The formatter of the field
 */

/**
 * Get the CSV content from the fields and rows
 * @param {Object} options
 * @param {Array<Field>} options.fields - The fields
 * @param {Array<Object>} options.rows - The rows
 * @returns {string} The CSV content
 */
export const getCSVContent = ({ fields, rows }) => {
  const [firstRow] = rows
  const fieldsToWrite = fields.filter((f) => Object.keys(firstRow).includes(f.key))
  const headerRow = fieldsToWrite.map((f) => `"${f.label}"`).join(',')
  const lines = rows.map((item, rowIdx) =>
    fieldsToWrite
      .map((f, colIdx) =>
        typeof f.formatter === 'function'
          ? f.formatter(item[f.key], {
            field: f,
            item,
            row: rowIdx,
            col: colIdx,
            plainText: true,
          })
          : item[f.key],
      )
      .map((v) => `"${`${v}`.replace(/[#"';,\t\r?\n]/gi, '')}"`)
      .join(','),
  )
  return `${headerRow}\n${lines.join('\n')}`
}
